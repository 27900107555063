import { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ImageView } from "../../../components/Image";

export function ProviderIntroHeader({ provider, onSchedule, onClose }) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // check for scroll event and set scroll value in state
    const onScroll = (e) => {
      setIsScrolled(e.target.scrollTop > 200);
    };
    const element = document.getElementById("provider-scroll");
    if (element) {
      element.addEventListener("scroll", onScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", onScroll);
      }
    };
  }, []);

  return (
    <>
      <div
        className={`fixed top-0 left-0 w-full pl-2.5 pr-4 py-2 bg-white shadow-[0px_4px_44px_0px_rgba(0,0,0,0.25)] justify-center items-center hidden lg:flex ${
          isScrolled
            ? "opacity-100 transition-[width_0.75s,height_0.75s,opacity_0.75s_0.75s]"
            : "opacity-0 transition-[width_0.75s_0.75s,height_0.75s_0.75s,opacity_0.75s]"
        }`}
      >
        <div className="max-w-[820px] w-[820px] mx-auto justify-between items-center flex">
          <div className="justify-start items-center gap-2.5 flex">
            <ImageView
              className="w-10 h-10 object-cover rounded-md"
              src="/images/profile1.png"
              alt="user"
              loading="lazy"
            />
            <div className="flex-col justify-start items-start gap-2 inline-flex">
              <div className="flex-col justify-start items-start gap-1.5 flex">
                <div className="text-black text-sm font-medium font-['Outfit'] leading-tight">
                  Arlene Smith
                </div>
              </div>
            </div>
          </div>
          <div className="justify-end items-center gap-2 flex">
            <Button
              variant="primary"
              className="py-[14px] px-6 !rounded-full"
              onClick={onSchedule}
            >
              <CalendarIcon className="w-5 h-5 relative [&>path]:fill-white" />
              <div className="text-white text-sm font-medium">
                Schedule Consultation
              </div>
            </Button>
            <button
              type="button"
              className="absolute right-5"
              onClick={onClose}
            >
              <CloseIcon className="w-8 h-8 relative" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
