export function AdminChatContactTabs({
  onTabChange,
  activeTab,
  numOfUnreadProviderConv,
  numOfUnreadClientConv
}: {
  activeTab: string;
  onTabChange: (tab: string) => void;
  numOfUnreadProviderConv: number;
  numOfUnreadClientConv: number;
}) {
  return (
    <div className="p-1.5 mb-[11px] rounded-lg border border-neutral-200 justify-start items-start flex w-full">
      <button
        onClick={() => onTabChange("providers")}
        className={`h-12 flex-1 px-4 py-[13px] ${activeTab === "providers"
          ? "bg-[#E8F3F2]"
          : "hover:bg-whiteButtonHover"
          } rounded-md justify-center items-center gap-2.5 flex`}
      >
        <div className="text-black text-base font-normal font-['Outfit'] leading-none">
          Providers
        </div>
        {numOfUnreadProviderConv > 0 && (
          <div className="w-5 h-5 p-2.5 mt-1 bg-primary rounded-3xl flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-[#FBF5E8]  text-xs font-bold ">
              {numOfUnreadProviderConv}
            </div>
          </div>
        )}
      </button>
      <button
        onClick={() => onTabChange("clients")}
        className={`h-12 flex-1 px-4 py-[13px] rounded-md justify-center items-center gap-2.5 flex ${activeTab === "clients" ? "bg-[#E8F3F2]" : "hover:bg-whiteButtonHover"
          }`}
      >
        <div className="text-black text-base font-normal">Clients</div>
        {numOfUnreadClientConv > 0 && (
          <div className="w-5 h-5 p-2.5 mt-1 bg-primary rounded-3xl flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-[#FBF5E8]  text-xs font-bold ">
              {numOfUnreadClientConv}
            </div>
          </div>
        )}
      </button>
    </div>
  );
}
