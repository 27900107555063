import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success.svg";
import React, { useState } from "react";
import moment from "moment";
import { AddShiftModal } from "./AddShiftModal";
import { DateTime } from "luxon";
import { RbtRequest, DAY, Shift, rbtRequestStatusColor, RbtRequestStatus, getRbtRequestStatusByValue } from "../RequestRbt.types";
import { zipCodes } from "./zip_codes";
import { CancelRbtRequestConfirm } from "./CancelConfirmRbtRequest";
import { Link } from "react-router-dom";
import { ShowToast } from "../../../services/toast";
import { api } from "../../../services/api";
import { PopupModal } from "../../../components/PopupModal";
import { TextInput } from "../../../components/TextInput";
import { TextArea } from "../../../components/Textarea";
import { SelectInput } from "../../../components/SelectInput";
import { DatePicker } from "../../../components/DatePicker";
import { Button } from "../../../components/Button";

export function RbtRequestModal({ rbtRequest, onClose }: { rbtRequest?: RbtRequest; onClose: () => void }) {
  const [form, setForm] = useState<RbtRequest>(rbtRequest ? rbtRequest : {
    pointPerson: '',
    owner: { firstName: '', lastName: '', id: '', isActive: true },
    ownerId: '',
    clientZipCode: '',
    clientLocationComments: '',
    qualifications: 'RBT_ONLY',
    weeklyHoursNeeded: '',
    shiftsNeeded: [],
    comments: '',
    desiredStart: new Date(),
    hireReason: 'NEW',
    rbtCandidates: [],
  });
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showAddShift, setShowAddShift] = useState(false);
  const [editMode, setEditMode] = useState(!rbtRequest)
  const [submitSuccess, setSubmitSuccess] = useState()
  const [deleteRequestId, setDeleteRequest] = useState<RbtRequest | undefined>()
  const [changeMade, setChangeMade] = useState(false)

  const isNewRequest = !rbtRequest

  React.useEffect(() => {
    // check for scroll event and set scroll value in state
    const onScroll = (e) => {
      setIsScrolled(e.target.scrollTop > 200);
    };
    const element = document.getElementById("provider-scroll");
    if (element) {
      element.addEventListener("scroll", onScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", onScroll);
      }
    };
  }, []);

  const isZipValid = (code: string) => code.trim().length === 5 && !isNaN(parseInt(code, 10)) && !!zipCodes[code]

  const prettyTime = (date: Date | string) => {
    const d = new Date(date)
    const mins = d.getMinutes() < 10 ? `${d.getMinutes()}0` : d.getMinutes()
    if (isNaN(d.getMinutes())) return date;
    return `${d.getHours()}:${mins}`
  }

  const handleCancel = async () => {
    if (rbtRequest?.id) {
      setDeleteRequest(rbtRequest)
    }
  }

  const handleSubmit = async () => {
    let newErrors = {}
    if (!form.clientZipCode || !isZipValid(form.clientZipCode)) {
      newErrors = { ...newErrors, clientZipCode: "Please provide client's valid five digit zip code" }
    }

    if ((form?.clientLocationComments?.length ?? 0) > 300) {
      newErrors = { ...newErrors, clientLocationComments: `Please keep comments about location under 300 characters (currently ${form.clientLocationComments?.length})` }
    }

    if (form.shiftsNeeded.length === 0) {
      newErrors = { ...newErrors, shiftsNeeded: "Please provide needed work sessions" }
    }

    if (!form.weeklyHoursNeeded || form.weeklyHoursNeeded <= 0 || form.weeklyHoursNeeded > 40) {
      newErrors = { ...newErrors, weeklyHoursNeeded: "Please provide weekly hours needed" }
    }

    if ((form?.comments?.length ?? 0) > 300) {
      newErrors = { ...newErrors, comments: `Please keep additional comments under 300 characters (currently ${form.comments?.length})` }
    }

    if (Object.keys(newErrors).length !== 0) {
      setErrors(newErrors)
      return
    }

    setErrors({})
    setLoading(true)

    if (isNewRequest) {
      const response = await api.createRbtRequest(form)
      if ([200, 201].includes(response.status)) {
        ShowToast({
          message: "You've successfully created a RBT Request",
          type: "success",
        });
        setSubmitSuccess(response.data.friendlyId)
      } else {
        ShowToast({
          message: response?.data?.message || "Something went wrong",
          type: "error",
        })
      }
    } else {
      const response = await api.updateRbtRequest(rbtRequest?.id, { ...form, owner: undefined })
      if ([200, 201].includes(response.status)) {
        ShowToast({
          message: `You've successfully updated RBT Request #${rbtRequest.friendlyId}`,
          type: "success",
        });
      } else {
        ShowToast({
          message: response?.data?.message || "Something went wrong",
          type: "error",
        })
      }
      onClose();
    }
    setLoading(false)

  };

  const zipCity = isZipValid(form.clientZipCode) && zipCodes[form.clientZipCode]?.city
  const zipState = isZipValid(form.clientZipCode) && zipCodes[form.clientZipCode]?.state_short

  const disabled = false;

  if (submitSuccess) {
    return <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[642px] max-w-[642px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div id="provider-scroll" className="w-full justify-between items-start flex">
          <div className="inline-block text-black text-2xl font-bold font-['Outfit'] leading-7">
            RBT Request Form
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full text-center">
          <SuccessIcon className="w-64 h-auto max-h-96 mx-auto" />
          <p className="text-xl mb-2">
            Request Created.
          </p>
        </div>
      </>
    </PopupModal >
  }


  return (
    <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[842px] max-w-[842px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div className="w-full justify-between items-start flex">
          <div className="inline-block text-black text-2xl font-bold font-['Outfit'] leading-7">
            RBT Request {isNewRequest ? 'Form' : `#${rbtRequest?.friendlyId}`}
            {!isNewRequest && rbtRequest.status && <div className="inline-block ml-5 px-2 h-5 rounded-lg gap-2.5" style={{ transform: "translateY(-3px)", backgroundColor: rbtRequestStatusColor(rbtRequest.status) }}>
              <div className="text-white text-sm font-normal">{rbtRequest.status}</div>
            </div>}
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full max-h-[calc(100vh-160px)] lg:max-h-[calc(100vh-155px)] overflow-auto" id="provider-scroll">



          <div className="mb-3">
            Provider Name: <span className="inline-block font-bold">{`${rbtRequest?.owner?.firstName} ${rbtRequest?.owner?.lastName}`}</span>
          </div>

          <div className="grid grid-cols-2 gap-10 mb-10">
            <div>
              <span className="text-black/80 text-sm">Headstart Point Person</span>
              <TextInput
                className={`h-10 ${!editMode && 'text-gray-400'}`}
                name="pointPerson"
                value={form.pointPerson}
                onChange={(e) => {
                  setForm({ ...form, pointPerson: e.target.value })
                  setChangeMade(true)
                }}
                disabled={!editMode}
              />
            </div>
            <div className="">
              <span className="text-black/80 text-sm">Status</span>
              <SelectInput
                data={Object.entries(RbtRequestStatus).map(([k, v]) => ({ value: k, label: v }))}
                name="status"
                value={getRbtRequestStatusByValue(form?.status)}
                disabledOptionText={"Select Status"}
                onChange={(e) => {
                  setForm({ ...form, status: RbtRequestStatus[e.target.value] ?? undefined });
                  setChangeMade(true)
                }}
                className={`h-10 py-0 ${!editMode && 'text-gray-500'}`}
                disabled={!editMode}
              />
            </div>
          </div>

          {rbtRequest?.isActive && <div className="text-center mb-8">
            <div className="font-bold">Candidates for Review</div>
            {form.rbtCandidates.length > 0 ? form.rbtCandidates.map((candidate, i) => (
              <div key={i} className="border border-gray-200 p-2 inline-block">
                <div className="inline-block mr-4">{candidate.firstName} {candidate.lastName}</div>
                <a target="_blank" href={candidate.resumeUrl} className="text-primary" rel="noreferrer">Download Resume</a>
              </div>
            )) : <div className="text-sm">No candidates currently assigned for review.</div>}
          </div>}

          <div className="grid grid-cols-2 gap-10">
            <div className="flex flex-col max-w-60 mb-5">
              <span className="text-black/80 text-sm">Client's Zip Code</span>
              <TextInput
                className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
                name="clientZip"
                value={form.clientZipCode}
                onChange={(e) => {
                  if (isZipValid(e.target.value)) {
                    setErrors({ ...errors, clientZipCode: undefined })
                  }
                  setForm({ ...form, clientZipCode: e.target.value })
                  setChangeMade(true)
                }}
                disabled={!editMode}
              />
              {zipCity && zipState && <div className="text-xs">Client City: {zipCity}, {zipState}</div>}
              {errors.clientZipCode && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.clientZipCode}
                </div>
              )}
            </div>
            <div className="flex flex-col max-w-60 mb-5">
              <span className="text-black/80 text-sm ml-1">Desired Start Date</span>
              <DatePicker
                className="w-max"
                onChange={(value) => {
                  setForm({ ...form, desiredStart: value });
                  setChangeMade(true)
                }}
                value={form.desiredStart}
                minDate={new Date()}
                minHeight="20px"
                disabled={!editMode}
              />
            </div>
          </div>

          <div className="flex flex-col mb-5">
            <span className="text-black/80 text-sm">Other Information On Client Location (Optional)</span>
            <TextArea
              className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
              name="clientLocationComments"
              rows={3}
              value={form.clientLocationComments}
              onChange={(e) => {
                setForm({ ...form, clientLocationComments: e.target.value });
                setChangeMade(true)
              }}
              disabled={!editMode}
            />
            {errors.clientLocationComments && (
              <div className="text-red-500 text-xs font-medium">
                {errors.clientLocationComments}
              </div>
            )}
          </div>

          <div className="flex flex-col mb-5">
            <div className="grid grid-cols-2 gap-10 px-1">
              <div>
                <div className="mb-3">
                  <span className="text-black/80 text-sm">Qualifications</span>
                  <SelectInput
                    data={[{ value: "RBT_ONLY", label: "Licensed RBT Only" }, { value: "BT_OR_RBT", label: "Either BT or RBT" }]}
                    name="qualifications"
                    value={form.qualifications}
                    disabledOptionText={"Select Required Qualification"}
                    onChange={(e) => {
                      setForm({ ...form, qualifications: e.target.value as "RBT_ONLY" | "BT_OR_RBT" });
                      setChangeMade(true)
                    }}
                    className={`h-16 ${!editMode && 'text-gray-400'}`}
                    disabled={!editMode}
                  />
                  {errors.qualifications && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.qualifications}
                    </div>
                  )}
                </div>

                <div className="flex flex-col mb-3">
                  <span className="text-black/80 text-sm">Total Weekly Hours</span>
                  <TextInput
                    type="number"
                    className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
                    name="clientZip"
                    value={form.weeklyHoursNeeded}
                    onChange={(e) => {
                      if (e.target.value === "") setForm({ ...form, weeklyHoursNeeded: '' })
                      const num = parseInt(e.target.value)
                      if (!isNaN(num) && num > 0 && num <= 40) {
                        setForm({ ...form, weeklyHoursNeeded: num })
                        setChangeMade(true)
                      }
                    }
                    }
                    disabled={!editMode}
                  />
                  {errors.weeklyHoursNeeded && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.weeklyHoursNeeded}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col mb-5">
                <span className="text-black/80 text-sm">RBT Work Schedule</span>
                <div className={`border rounded-lg min-h-[155px] p-2 relative ${!editMode && 'text-gray-400'}`} style={editMode ? {} : { "backgroundColor": "rgba(239, 239, 239, 0.3)" }}>
                  {form.shiftsNeeded.map((shift, i) =>
                  (<div key={i} className="text-bold">{`${shift.day}: ${prettyTime(shift.startTime)} - ${prettyTime(shift.endTime)}`}
                    {shift.timeZone && ` (${DateTime.local().setZone(shift.timeZone).offsetNameShort})`}
                    {editMode && <div className="inline-block text-sm text-red-600 cursor-pointer ml-1" onClick={() => {
                      setForm({
                        ...form, shiftsNeeded: form.shiftsNeeded.length === 1 ? [] : form.shiftsNeeded.splice(i, 1) && form.shiftsNeeded
                      });
                      setChangeMade(true)
                    }}
                    > (Remove)</div>}
                  </div>)
                  )}
                  {form.shiftsNeeded.length === 0 && <div>No sessions added</div>}
                  {showAddShift && <AddShiftModal title="Add RBT Work Schedule" onClose={() => setShowAddShift(false)} onAdd={(shifts: Array<Shift>) => {
                    setForm({
                      ...form, shiftsNeeded:
                        [...form.shiftsNeeded, ...shifts].sort((a, b) => {
                          if (a.day === b.day) {
                            return a.startTime.getTime() - b.startTime.getTime()
                          }
                          return [...Object.keys(DAY)].indexOf(a.day) - [...Object.keys(DAY)].indexOf(b.day)
                        })
                    })
                    setChangeMade(true)
                    setErrors({ ...errors, shiftsNeeded: undefined })
                  }} />}
                  {editMode && <div className="text-primary cursor-pointer absolute bottom-2 right-3" onClick={() => setShowAddShift(true)}>Add Session</div>}
                </div>
                {errors.shiftsNeeded && (
                  <div className="text-red-500 text-xs font-medium">
                    {errors.shiftsNeeded}
                  </div>
                )}
              </div>

            </div>
          </div>

          <div className="mb-5">
            <span className="text-black/80 text-sm">Which of the following best describes the reason for your request?</span>
            <SelectInput
              data={[
                { value: "NEW", label: "Adding a New Client or Offering Additional Direct Therapy Hours" },
                { value: "REPLACE", label: "Hiring to Replace an Existing RBT" },
                { value: "BOTH", label: "Both for New Client/Hours and Replacing Existing RBT" }
              ]}
              name="qualifications"
              value={form.hireReason}
              disabledOptionText={"Select reason for hiring"}
              onChange={(e) => {
                setForm({ ...form, hireReason: e.target.value as 'NEW' | 'REPLACE' });
                setChangeMade(true)
              }}
              className={`h-16 ${!editMode && 'text-gray-400'}`}
              disabled={!editMode}
            />
            {errors.hireReason && (
              <div className="text-red-500 text-xs font-medium">
                {errors.hireReason}
              </div>
            )}
          </div>

          <div className="flex flex-col mb-2">
            <span className="text-black/80 text-sm">Additional Comments (Optional)</span>
            <TextArea
              className={`!p-[14px] ${!editMode && 'text-gray-400'}`}
              name="comments"
              rows={3}
              value={form.comments}
              onChange={(e) => {
                setForm({ ...form, comments: e.target.value });
                setChangeMade(true)
              }}
              disabled={!editMode}
            />
            {errors.comments && (
              <div className="text-red-500 text-xs font-medium">
                {errors.comments}
              </div>
            )}
          </div>

        </div>


        <div className="w-full">
          {isNewRequest ?
            <Button
              disabled={disabled || loading}
              variant="primary"
              className="!rounded-full w-full mt-0"
              onClick={handleSubmit}
              loading={loading}
            >
              Submit
            </Button>
            :
            <div className="grid grid-cols-2 gap-10 px-1">
              <Button
                disabled={disabled || loading || editMode || (rbtRequest && rbtRequest?.status === RbtRequestStatus.CANCELED)}
                variant="primary"
                className="!rounded-full w-full mt-0 bg-red-500 text-white"
                onClick={handleCancel}
                loading={loading}
              >
                Cancel Request
              </Button>
              <Button
                disabled={disabled || loading}
                variant={(editMode && !changeMade) ? "outline" : "primary"}
                className="!rounded-full w-full mt-0"
                onClick={() => editMode ? ((changeMade) ? handleSubmit() : setEditMode(false)) : setEditMode(!editMode)}
                loading={loading}
              >
                {editMode ? ((changeMade) ? "Save" : "Stop Editing") : "Edit Request"}
              </Button>
            </div>
          }
        </div>



        {deleteRequestId && <CancelRbtRequestConfirm
          onCancel={() => setDeleteRequest(undefined)}
          onDelete={onClose}
          request={deleteRequestId} />}

      </>
    </PopupModal >
  );
}
