export enum HowDidYouHearAboutUs {
  HeadstartCareProvider = "Headstart care provider",
  ReferralFromAnotherCareProvider = "Referral from another care provider",
  Insurance = "Insurance",
  SchoolOrDaycare = "School or daycare",
  ParentSupportGroupOrResourceCenter = "Parent support group or resource center",
  Google = "Google",
  SocialMedia = "Social media",
  Other = "Other",
}

export enum ClientStatus {
  ACTIVE = "Active",
  NOT_INTERESTED = "Not Interested",
  UNRESPONSIVE = "Unresponsive",
}

export const clientStatusColor = (status: ClientStatus) => {
  switch (status) {
    case ClientStatus.ACTIVE:
      return "black";
    case ClientStatus.NOT_INTERESTED:
      return "#BA8E23"; //yellow
    case ClientStatus.UNRESPONSIVE:
      return "rgb(239 68 68)"; //red
  }
};
